import {useCpUser} from '~/composables/useCpUser';
import {QUERY_KEYS} from '~/utils/services/keys';
import {rejectQueryFn} from '~/utils';
import {addToProductHistory, getProductHistory} from '~/utils/services/api';
import {deleteUserHistory} from '~/composables';

export const useProductHistory = (page = 1) => {
    const {userEmail} = useCpUser();
    return useAsyncData(QUERY_KEYS.account.productHistory, () => {
        if (userEmail) {
            return getProductHistory(userEmail, page);
        } else {
            return rejectQueryFn();
        }
    });
};

export const useAddToProductHistory = (articleId: string, server = true) => {
    const {userEmail} = useCpUser();
    return useAsyncData(QUERY_KEYS.account.addProductHistory, () => {
        if (userEmail) {
            return addToProductHistory(articleId, userEmail);
        } else {
            return rejectQueryFn();
        }
    }, {server});
};

export const useDeleteProductHistory = () => {
    return useAsyncData(QUERY_KEYS.account.deleteProductHistory, () => {
        return deleteUserHistory();
    });
};
