import type {ICpArticle, IPaginatedResponse} from '~/types';
import {accountPaths} from '~/utils/services/paths';

export type IProductHistoryType = {
    article: ICpArticle
    id: string
    last_visit: string
    month: string
    year: string
}
export const getProductHistory = async (userEmail: string, page: number) => {
    return await $cpFetch<IPaginatedResponse<IProductHistoryType>>(accountPaths.productsHistory(userEmail), {
        params: {
            'page[number]': page,
            'page[size]': 100,
        },
    });
};

export const addToProductHistory = async (articleId: string, userEmail: string) => {
    return await $cpFetch(accountPaths.productsHistory(userEmail), {
        method: 'POST',
        body: {
            article: articleId,
        },
    });
};
